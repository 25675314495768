<template>
	<div id="meetingDetail">
		<div v-loading="loading" :element-loading-text="loadingText" element-loading-spinner="el-icon-loading">
			<!-- 会议详情 -->
			<div class="detailHeader">
				<div class="title">会议主题：{{meetingInfo.meetingTitle}}</div>
				<div class="tabs">
					<div :class="tabCk=='1'?'item active':'item'" name="1" @click="tabCk='1'">会议详情</div>
					<div :class="tabCk=='2'?'item active':'item'" name="2" @click="tabCk='2'">会议医生信息</div>
					<div :class="tabCk=='4'?'item active':'item'" name="4" @click="tabCk='4'">劳务明细</div>
					<div :class="tabCk=='5'?'item active':'item'" name="5" @click="tabCk='5'">直播回放</div>
					<div :class="tabCk=='6'?'item active':'item'" name="6" @click="tabCk='6'">参会链接</div>
					<div v-if="!'WAIT,LIVING'.includes(meetingInfo.meetingStatus)"
						:class="tabCk=='3'?'item active tab3':'item tab3'" name="3" @click="tabCk='3'">合规材料</div>
				</div>
			</div>

			<div class="details" v-if="tabCk == '1'">
				<div class="detailsMain">
					<!-- 会议详情 -->
					<div class="detailLeft">
						<el-row :gutter="20" v-if="meetingInfo.reimbursementNum">
							<el-col :span="24">
								<p class="tit">金蝶单号</p>
								<div class="main">{{meetingInfo.reimbursementNum}}</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<p class="tit">会议主题</p>
								<div class="main">{{meetingInfo.meetingTitle}}</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<p class="tit">所属项目</p>
								<div class="main">{{meetingInfo.projectName}}</div>
							</el-col>
							<el-col :span="12">
								<p class="tit">会议类型</p>
								<div class="main" v-if="meetingInfo.meetingFormat == 'ONLINE'">万木线上会</div>
								<div class="main" v-else-if="meetingInfo.meetingFormat == 'TENCENT'">腾讯线上会</div>
								<div class="main" v-else-if="meetingInfo.meetingFormat == 'OFFLINE'">线下会</div>
								<div class="main" v-else>仅管理</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<p class="tit">会议日期</p>
								<div class="main">
									{{$moment( meetingInfo.meetingStartTime).format('lll')}}
									<!-- {{$moment( meetingInfo.meetingStartTime).calendar()}} -->
								</div>
							</el-col>
							<el-col :span="12">
								<p class="tit">立项主体单位</p>
								<div class="main">{{mechanism[meetingInfo.meetingMechanismId]}}</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<p class="tit">支持企业</p>
								<div class="main">{{enterPrise[meetingInfo.meetingEnterpriseId]}}</div>
							</el-col>
							<el-col :span="12">
								<p class="tit">执行机构</p>
								<div class="main">{{actuator[meetingInfo.meetingActuatorId]}}</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<p lass="tit">会务负责人</p>
								<div class="main" v-if="meetingInfo.meetingActuatorIds">
									<template v-for="item in orgListUser">
										<template v-if="user == item.id"
											v-for="(user,idx) in meetingInfo.meetingActuatorIds">
											{{item.fullName}}
											<template v-if="idx<meetingInfo.meetingActuatorIds.length -1">,</template>
										</template>
									</template>
								</div>
							</el-col>

							<el-col :span="12">
								<p lass="tit">劳务负责人</p>
								<div class="main" v-if="meetingInfo.laborActuatorIds">
									<template v-for="item in orgListUser">
										<template v-if="user == item.id"
											v-for="(user,idx) in meetingInfo.laborActuatorIds">
											{{item.fullName}}
											<template v-if="idx<meetingInfo.laborActuatorIds.length -1">,</template>
										</template>
									</template>
								</div>
							</el-col>
						</el-row>
					</div>
					<!-- 					<div class="detailRight">
						<el-row :gutter="20" v-for="(item,key) in meetingInfo.userList" :key="key">
							<el-col :span="24">
								<p class="tit">{{key}}</p>
								<div class="main" v-for="(sec,k2) in item" :key="k2">
									{{k2}}
									<div class="users">
										<div class="user" v-for="user in sec" :key="user.fullName">
											<el-image
												:style="{'background':color[Math.floor(Math.random()*color.length)][0],'color':color[Math.floor(Math.random()*color.length)][1]}"
												lazy class="avatar" :src="user.fullName" fit="cover">
												<div slot="placeholder" class="image-slot"><i class="el-icon-loading" />
												</div>
												<div slot="error" class="image-slot">
													{{subString(user.fullName)}}
												</div>
											</el-image>
											<span>{{user.fullName}}</span>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<p class="tit">项目进度</p>
								<div class="schedule">
									<template v-for="log in meetingInfo.logs">
										<div class="node" :key="log.id">
											<div class="time">
												<i class="el-icon-date"></i>
												{{log.updateTime?$moment(log.updateTime).format('LL'): '-- : --'}}
											</div>
											<div class="type">
												<el-checkbox :disabled="!log.updateTime"
													:value="!!log.updateTime">{{log.status}}</el-checkbox>
												<el-image
													:style="{'background':color[Math.floor(Math.random()*color.length)][0],'color':color[Math.floor(Math.random()*color.length)][1]}"
													lazy class="el-avatar" :src="log.actuatorUpdateFullName" fit="cover"
													v-if="log.updateTime">
													<div slot="placeholder" class="image-slot"><i
															class="el-icon-loading" />
													</div>
													<div slot="error" class="image-slot">
														{{subString(log.actuatorUpdateFullName)}}
													</div>
												</el-image>
												{{log.actuatorUpdateFullName}}
											</div>
										</div>
									</template>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<p class="tit">回款情况备注</p>
								<div class="mainArea ">
									结算材料已更改，催促项目官员审核结算
									···
								</div>
							</el-col>
						</el-row>
					</div> -->

				</div>
				<div class="options">
					<!-- <el-button size="small"
						v-if="meetingInfo.meetingStatus=='WAIT'||meetingInfo.meetingStatus=='LIVING'" type="primary"
						class="enter" @click.stop="enterTheMeeting()">进入会议</el-button> -->
					<el-button size="small" plain type="primary" @click="ChangeMeetingDetail()"
						v-if="meetingInfo.meetingStatus=='WAIT'">修改会议信息</el-button>
					<el-button size="small" plain type="danger" @click="ChangeMeetingDetail()"
						v-if="meetingInfo.meetingStatus=='WAIT_CLAIM'">完善信息</el-button>
					<el-button size="small" plain type="warning" @click="delMeetingFun()"
						v-if="meetingInfo.meetingStatus=='WAIT'">取消会议</el-button>
					<el-button size="small" plain type="danger" class="enter" @click.stop="closeTheMeeting()"
						v-if="meetingInfo.meetingStatus=='WAIT'||(meetingInfo.meetingStatus=='LIVING'&&meetingInfo.meetingFormat!='ONLIEN')">结束会议</el-button>
				</div>
			</div>

			<div class="proDocInfo" v-if="tabCk == '2'">
				<!-- 会议医生信息 -->
				<el-table :data="userList" key="meetingUserTable" style="width: 100%">
					<el-table-column prop="fullName" label="姓名" align="cneter" width="200"></el-table-column>
					<el-table-column prop="phoneNumber" label="手机号码" align="cneter"></el-table-column>
					<el-table-column prop="title" label="职称" align="cneter"></el-table-column>
					<el-table-column prop="hospital" label="医院" align="cneter"></el-table-column>
					<!-- <el-table-column prop="type" label="会议角色" align="cneter"></el-table-column>
				<el-table-column label="状态" align="cneter">
					<template slot-scope="scope">
						<el-tag size="mini" type="success" v-if="scope.row.status == '已签署'">已签署</el-tag>
						<el-tag size="mini" type="danger" v-if="scope.row.status == '待签署'">待签署</el-tag>
						<el-tag size="mini" type="info" v-if="scope.row.status == '已取消'">已取消</el-tag>
					</template>
				</el-table-column> -->
					<el-table-column label="四证查看" align="cneter" width="160">
						<template slot-scope="scope">
							<el-button size="mini" @click="viewCertificate(scope.row)">查看</el-button>
							<el-dialog v-if="certificateShow" title="四证查看" :visible.sync="certificateShow"
								width="600px">
								<el-image v-for="(url,i) in certificateList" :key="i" :src="url"
									:preview-src-list="certificateList"
									style="width: 100px; height: 100px; margin: 5px;" fit="cover">
								</el-image>
							</el-dialog>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="comMate" v-if="tabCk == '3'">
				<!-- 合规材料 -->
				<div class="option">
					<el-dropdown @command="handleCommand" trigger="click" placement="bottom">
						<el-button type="primary" size="mini">
							下载文件 <i class="el-icon-arrow-down el-icon--right"></i>
						</el-button>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="1">导出所有劳务协议</el-dropdown-item>
							<el-dropdown-item command="2">导出全部用户文件</el-dropdown-item>
							<el-dropdown-item command="3">导出所有通用文件</el-dropdown-item>
							<el-dropdown-item command="4">导出所有发票</el-dropdown-item>
							<el-dropdown-item command="6">导出所有会议照片</el-dropdown-item>
							<el-dropdown-item command="7">导出劳务协议明细表</el-dropdown-item>
							<el-dropdown-item command="8">导出签到表</el-dropdown-item>
							<el-dropdown-item command="5">下载所有文件</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- <el-button size="mini" @click="downloadAgreementFun">下载劳务协议</el-button>
					<el-button size="mini" @click="downloadAllFun">下载所有文件</el-button> -->
				</div>
				<div class="complianceMaterials" v-if="meetFile||meetFile.values">
					<div class="assemble">
						<template v-for="item in annotation">
							<div class="compRow" :key="item.key" v-if="item.key!='file7'">
								<template v-for="(val,key) in meetFile.values">
									<template v-if=" key == item.key">
										<div :key="key" class="compTit">
											<h1>{{item.text}}</h1>
											<i class="el-icon-success" v-if="meetFile.status[key]=='SUCCESS'"></i>
											<i class="el-icon-warning"
												v-if="(meetFile.fileErrorMessages[key]||meetFile.errorMessages[key])&&meetFile.status[key]!='SUCCESS'"></i>
											<span
												v-if="meetFile.fileErrorMessages[key]&&meetFile.status[key]!='SUCCESS'">
												{{meetFile.fileErrorMessages[key]}}</span>
											<template v-if="key!='file7'&&meetFile.status[key]!='SUCCESS'">
												<span v-for="(msg,i) in meetFile.errorMessages[key]"
													:key="i">{{msg}}。</span>
											</template>
										</div>

										<div class="compVal" :key="`${key}-false`" v-if="key != 'file7'">
											<div class="item" v-for="(item,valKey) in val" :key="valKey"
												:title="meetFile.fileNames[key][valKey]">
												<div class="FileView">
													<span class="new"
														v-if="meetFile.updateMessages[key][valKey]">new</span>
													<el-image v-if="isImage(item)" :src="item" fit="cover">
														<div slot="error" class="image-slot">
															<img :src="require('@/assets/imgs/i_img.png')" />
														</div>
													</el-image>
													<el-image v-else-if="isPdf(item)"
														:src="require('@/assets/imgs/pdf.png')" fit="cover"></el-image>
													<el-image v-else-if="isZip(item)"
														:src="require('@/assets/imgs/zip.png')" fit="cover"></el-image>
													<el-image v-else-if="isPpt(item)"
														:src="require('@/assets/imgs/ppt.png')" fit="cover"></el-image>
													<el-image v-else-if="isDoc(item)"
														:src="require('@/assets/imgs/doc.png')" fit="cover"></el-image>
													<el-image v-else-if="isXls(item)"
														:src="require('@/assets/imgs/xls.png')" fit="cover"></el-image>
													<el-image v-else :src="require('@/assets/imgs/i_file.svg')"
														fit="cover"></el-image>
												</div>
												<div class="FileName">
													{{meetFile.fileNames[key][valKey]}}
												</div>
												<div class="viewOpts">
													<div class="btn view" type="text"
														@click="preview(item,meetFile.fileNames[key][valKey])">查看 </div>
													<div v-if="meetFile.status[key]!='SUCCESS'" class="btn label"
														type="text"
														@click="downloadFile(item,meetFile.fileNames[key][valKey])">
														下载</div>
												</div>
											</div>
											<div class="noFile" v-if="!val[0]">
												<i class="el-icon-folder-delete"></i>
												没有文件
											</div>
										</div>
									</template>
								</template>
							</div>
						</template>
					</div>
					<template v-for="item in annotation">
						<div class="compRow" :key="item.key" v-if="item.key=='file7'">
							<template v-for="(val,key) in meetFile.values">
								<template v-if=" key == item.key">
									<div :key="key" class="compTit">
										<h1>{{item.text}}</h1>
										<i class="el-icon-success" v-if="meetFile.status[key]=='SUCCESS'"></i>
										<i class="el-icon-warning"
											v-if="(meetFile.fileErrorMessages[key]||meetFile.errorMessages[key])&&meetFile.status[key]!='SUCCESS'"></i>
										<span v-if="meetFile.fileErrorMessages[key]&&meetFile.status[key]!='SUCCESS'">
											{{meetFile.fileErrorMessages[key]}}</span>
										<template v-if="key!='file7'&&meetFile.status[key]!='SUCCESS'">
											<span v-for="(msg,i) in meetFile.errorMessages[key]"
												:key="i">{{msg}}。</span>
										</template>
									</div>

									<div class="compFile7" :key="`${key}-true`" v-if="key == 'file7'">
										<template v-for="(item,valKey,idx) in val">
											<div class="item" :key="valKey"
												v-if="!valKey.includes('会议照片')&&!valKey.includes('通用文件夹')">
												<el-divider v-if="idx!=2"></el-divider>
												<div class="compTit">
													<h2>{{valKey.split("-")[1]}}
														<label v-if="valKey.split('-')[2]">
															所属会议：{{valKey.split("-")[2]}}</label>
													</h2>
													<template v-for="(row,rowKey) in item">
														<template
															v-if="meetFile.errorMessages[key]&&meetFile.errorMessages[key][valKey]&&meetFile.errorMessages[key][valKey][rowKey]&&meetFile.status[key]!='SUCCESS'">
															<span
																v-for="(msg,i) in meetFile.errorMessages[key][valKey][rowKey]"
																:key="`${key}${valKey}${rowKey}${i}`">
																{{msg}}。
															</span>
														</template>
													</template>
												</div>
												<div class="fileRow">
													<template class="row" v-for="(row,rowKey) in item">
														<div class="fileItem" v-for="(item,idx) in row"
															:key="`${key}${valKey}${rowKey}${idx}`"
															:title="meetFile.fileNames[key][valKey][rowKey][idx]">
															<div class="FileView">
																<span class="new"
																	v-if="meetFile.updateMessages[key][valKey][rowKey][idx]">new</span>
																<el-image v-if="isImage(item)" :src="item" fit="cover">
																	<div slot="error" class="image-slot">
																		<img
																			:src="require('@/assets/imgs/i_img.png')" />
																	</div>
																</el-image>
																<el-image v-else-if="isPdf(item)"
																	:src="require('@/assets/imgs/pdf.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isZip(item)"
																	:src="require('@/assets/imgs/zip.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isPpt(item)"
																	:src="require('@/assets/imgs/ppt.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isDoc(item)"
																	:src="require('@/assets/imgs/doc.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isXls(item)"
																	:src="require('@/assets/imgs/xls.png')"
																	fit="cover"></el-image>
																<el-image v-else
																	:src="require('@/assets/imgs/i_file.svg')"
																	fit="cover"></el-image>
															</div>
															<div class="FileName">
																{{meetFile.fileNames[key][valKey][rowKey][idx]}}
															</div>
															<div class="viewOpts">
																<div class="btn view" type="text"
																	@click="preview(item,meetFile.fileNames[key][valKey][rowKey][idx])">
																	查看
																</div>
																<div v-if="meetFile.status[key]!='SUCCESS'"
																	class="btn label" type="text"
																	@click="downloadFile(item,meetFile.fileNames[key][valKey][rowKey][idx])">
																	下载</div>
															</div>
														</div>
													</template>
													<div class="noFile" v-if="noFile(item)" :key="valKey">
														<i class="el-icon-folder-delete"></i>
														没有文件
													</div>
												</div>
											</div>
										</template>
										<template v-for="(item,valKey,idx) in val">
											<div class="item" :key="valKey"
												v-if="valKey.includes('会议照片')||valKey.includes('通用文件夹')">
												<el-divider></el-divider>
												<div class="compTit">
													<h2>{{valKey.split("-")[1]}}
														<label v-if="valKey.split('-')[2]">
															所属会议：{{valKey.split("-")[2]}}</label>
													</h2>
													<template v-for="(row,rowKey) in item">
														<template
															v-if="meetFile.errorMessages[key]&&meetFile.errorMessages[key][valKey]&&meetFile.errorMessages[key][valKey][rowKey]&&meetFile.status[key]!='SUCCESS'">
															<span
																v-for="(msg,i) in meetFile.errorMessages[key][valKey][rowKey]"
																:key="`${key}${valKey}${rowKey}${i}`">
																{{msg}}。
															</span>
														</template>
													</template>
												</div>
												<div class="fileRow">
													<template class="row" v-for="(row,rowKey) in item">
														<div class="fileItem" v-for="(item,idx) in row"
															:key="`${key}${valKey}${rowKey}${idx}`"
															:title="meetFile.fileNames[key][valKey][rowKey][idx]">
															<div class="FileView">
																<span class="new"
																	v-if="meetFile.updateMessages[key][valKey][rowKey][idx]">new</span>
																<el-image v-if="isImage(item)" :src="item" fit="cover">
																	<div slot="error" class="image-slot">
																		<img
																			:src="require('@/assets/imgs/i_img.png')" />
																	</div>
																</el-image>
																<el-image v-else-if="isPdf(item)"
																	:src="require('@/assets/imgs/pdf.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isZip(item)"
																	:src="require('@/assets/imgs/zip.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isPpt(item)"
																	:src="require('@/assets/imgs/ppt.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isDoc(item)"
																	:src="require('@/assets/imgs/doc.png')"
																	fit="cover"></el-image>
																<el-image v-else-if="isXls(item)"
																	:src="require('@/assets/imgs/xls.png')"
																	fit="cover"></el-image>
																<el-image v-else
																	:src="require('@/assets/imgs/i_file.svg')"
																	fit="cover"></el-image>
															</div>
															<div class="FileName">
																{{meetFile.fileNames[key][valKey][rowKey][idx]}}
															</div>
															<div class="viewOpts">
																<div class="btn view" type="text"
																	@click="preview(item,meetFile.fileNames[key][valKey][rowKey][idx])">
																	查看
																</div>
																<div v-if="meetFile.status[key]!='SUCCESS'"
																	class="btn label" type="text"
																	@click="downloadFile(item,meetFile.fileNames[key][valKey][rowKey][idx])">
																	下载</div>
															</div>
														</div>
													</template>
													<div class="noFile" v-if="noFile(item)" :key="valKey">
														<i class="el-icon-folder-delete"></i>
														没有文件
													</div>
												</div>
											</div>
										</template>

									</div>

									<!-- <div class="compVal" :key="`${key}-false`" v-if="key != 'file7'">
											<div class="item" v-for="(item,valKey) in val" :key="valKey"
												:title="meetFile.fileNames[key][valKey]">
												<div class="FileView">
													<span class="new"
														v-if="meetFile.updateMessages[key][valKey]">new</span>
													<el-image v-if="isImage(item)" :src="item" fit="cover">
														<div slot="error" class="image-slot">
															<img :src="require('@/assets/imgs/i_img.png')" />
														</div>
													</el-image>
													<el-image v-else-if="isPdf(item)"
														:src="require('@/assets/imgs/pdf.png')" fit="cover"></el-image>
													<el-image v-else-if="isZip(item)"
														:src="require('@/assets/imgs/zip.png')" fit="cover"></el-image>
													<el-image v-else-if="isPpt(item)"
														:src="require('@/assets/imgs/ppt.png')" fit="cover"></el-image>
													<el-image v-else-if="isDoc(item)"
														:src="require('@/assets/imgs/doc.png')" fit="cover"></el-image>
													<el-image v-else-if="isXls(item)"
														:src="require('@/assets/imgs/xls.png')" fit="cover"></el-image>
													<el-image v-else :src="require('@/assets/imgs/i_file.svg')"
														fit="cover"></el-image>
												</div>
												<div class="FileName">
													{{meetFile.fileNames[key][valKey]}}
												</div>
												<div class="viewOpts">
													<div class="btn view" type="text"
														@click="preview(item,meetFile.fileNames[key][valKey])">查看 </div>
													<div v-if="meetFile.status[key]!='SUCCESS'" class="btn label"
														type="text"
														@click="downloadFile(item,meetFile.fileNames[key][valKey])">
														下载</div>
												</div>
											</div>
											<div class="noFile" v-if="!val[0]">
												<i class="el-icon-folder-delete"></i>
												没有文件
											</div>
										</div>
									 -->
								</template>
							</template>
						</div>
					</template>


				</div>
				<div class="null" v-if="!meetFile&&!meetFile.values">列表为空</div>
				<div class="opts" v-if="meetingInfo.meetingStatus=='UPLOAD'">
					<el-button icon="el-icon-upload" type="primary" plain
						@click="goUpload({id:meetingInfo.id})">修改</el-button>
				</div>
				<div class="opts"
					v-if="meetingInfo.meetingStatus=='AI_REVIEW_SUCCESS'||meetingInfo.meetingStatus=='AI_REVIEW_ERROR_ACCEPT'">
					<!-- ||meetingInfo.meetingStatus=='OFFICIAL_ERROR' -->
					<!-- 各种操作按钮 -->
					<el-button icon="el-icon-upload" type="primary" plain
						@click="goUpload({id:meetingInfo.id})">修改</el-button>
					<el-button icon="el-icon-circle-close" type="warning" plain
						@click="materialAudit(false)">驳回</el-button>
					<el-button icon="el-icon-circle-check" type="primary" plain
						@click="materialAudit(true)">通过</el-button>
				</div>
			</div>

			<div class="detailServices" v-if="tabCk == '4'">
				<div class="opts"
					v-if="meetingInfo.meetingStatus!='WAIT'&&meetingInfo.meetingStatus!='LIVING'&&meetingInfo.meetingStatus!='SUCCESS'">
					<el-link target="_blank" type="primary"
						href="//life-oasis.oss-cn-beijing.aliyuncs.com/video/%E5%8A%B3%E5%8A%A1%E5%8D%8F%E8%AE%AE%E6%98%8E%E7%BB%86.xlsx">下载劳务明细模版</el-link>
					<el-upload class="upload" :action="`${baseUrl}/sign/live/official/importMeetingOrder`"
						:data="{meetingId:$route.query.meetingId}" :headers="{token:token}" multiple
						:on-progress="onProgress" :on-success="File6Success" :show-file-list="false">
						<el-button size="mini" type="primary" class="uploadFile">上传</el-button>
					</el-upload>
				</div>
				<!-- 劳务明细 -->
				<el-table :data="orderList.orders" key="orderTable" style="width: 100%">
					<el-table-column prop="fullName" label="姓名" align="center" width="100"></el-table-column>
					<el-table-column prop="meetingTitle" label="会议主题" min-width="200">
						<template slot-scope="scope">
							<span v-if="scope.row.orderType=='system'">{{meetingInfo.meetingTitle}}</span>
							<span v-else>{{scope.row.meetingTitle}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="bankNumber" label="银行卡号" min-width="150"></el-table-column>
					<el-table-column prop="bankName" label="开户行" width="200"></el-table-column>
					<el-table-column prop="preTax" label="税前金额" width="100" align="center"></el-table-column>
					<el-table-column prop="afterTax" label="税后金额" width="100" align="center"></el-table-column>
					<el-table-column prop="orderType" label="劳务所属会议" width="120" align="center">
						<template slot-scope="scope">
							<span v-if="scope.row.orderType=='system'">本会议</span>
							<span v-else>其他会议</span>
						</template>
					</el-table-column>
					<el-table-column label="协议状态" width="80" align="center">
						<template slot-scope="scope">
							<el-tag size="mini" type="primary" v-if="scope.row.agreementStatus == 'WAIT'">待签署</el-tag>
							<el-tag size="mini" type="info" v-if="scope.row.agreementStatus == 'END'">已取消</el-tag>
							<el-tag size="mini" type="success" v-if="scope.row.agreementStatus == 'FINISH'">已签署</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="劳务状态" width="80" align="center">
						<template slot-scope="scope">
							<el-tag size="mini" type="primary" v-if="scope.row.orderStatus == 'WAIT'">待支付</el-tag>
							<el-tag size="mini" type="info" v-if="scope.row.orderStatus == 'END'">已取消</el-tag>
							<el-tag size="mini" type="danger" v-if="scope.row.orderStatus == 'ERROR'">已驳回</el-tag>
							<el-tag size="mini" type="danger" v-if="scope.row.orderStatus == 'REJECT'">已驳回</el-tag>
							<el-tag size="mini" type="success" v-if="scope.row.orderStatus == 'FINISH'">已支付</el-tag>
							<el-tag size="mini" type="primary" v-if="scope.row.orderStatus == 'PROGRESS'">进行中</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="150" class="tableOpts" fixed="right" align="center">
						<template slot-scope="scope">
							<!-- {{scope.$index}} -->
							<el-button @click="sendTextFun(agreementList[scope.$index])" size='mini' type="text"
								v-if="agreementList[scope.$index]&&agreementList[scope.$index].isSend=='NO'&&agreementList[scope.$index].status=='WAIT'">
								短信提醒
							</el-button>
							<el-button @click="sendTextFun(agreementList[scope.$index])" size='mini' type="text"
								v-else-if="agreementList[scope.$index]&&agreementList[scope.$index].isSend=='YES'&&agreementList[scope.$index].status=='WAIT'">
								再次提醒
							</el-button>
							<el-button size='mini' type="text" @click="changeDetailShowDlog(scope.row)"
								v-if="scope.row.orderType!='system'">修改</el-button>
							<el-button type="text" size="mini" @click="deleteDetail(scope.row)"
								v-if="scope.row.orderType!='system'">删除</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div class="opts2" v-if="orderList.orders[0]">
					<el-button type="primary" @click="getMeetingOrderList">查看支付明细</el-button>
				</div>
			</div>

			<div class="livePlayback" v-if="tabCk == '5'">
				<!-- 视频回放 -->
				<el-upload class="uploadVideo" :action="`${baseUrl}/sign/live/official/uploadMeetingVideo`"
					:data="{meetingId:$route.query.meetingId}" :headers="{'token':token}" :multiple="false"
					:file-list="videoList" :show-file-list="false" :on-success="videoSuccess"
					:before-upload="videoBeforeUpload">
					<el-button size="small" type="primary">上传直播回放</el-button>
				</el-upload>
				<el-table :data="meetingInfo.videos" key="videTable" style="width: 100%">
					<el-table-column prop="id" label="id" width="100" align="cneter"></el-table-column>
					<el-table-column label="名称" align="cneter">
						<template slot-scope="scope">
							【{{meetingInfo.meetingTitle}}】
							会议录像： {{scope.row.fileName}}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="300" align="cneter">
						<template slot-scope="scope">
							<el-button size="mini" @click="viewVideo(scope.row)">查看</el-button>
							<el-button size="mini" @click="download(scope.row)">下载</el-button>
							<el-button size="mini" @click="deleteVideo(scope.row)">删除</el-button>
							<el-dialog class="agreementDialog" v-if="viewVideoType" title="查看回放"
								:visible.sync="viewVideoType" width="1000px">
								<video :src="showVideo" controls autoplay></video>
							</el-dialog>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="participationLink" label="参会链接" v-if="tabCk=='6'">
				<div class="row">
					<div class="col">
						<div class="qrcode">
							<h4>
								会议签到
								<span>点击二维码下载</span>
							</h4>
							<img :src="meetingInfo.signCodeUrl" alt="" @click="downloadQrCode(meetingInfo.signCodeUrl)">
						</div>
					</div>

					<div class="col">
						<div class="qrcode">
							<h4>劳务签署<span>点击二维码下载</span></h4>
							<img :src="meetingInfo.inviteCodeUrl" alt=""
								@click="downloadQrCode(meetingInfo.inviteCodeUrl)">
						</div>
					</div>

					<div class="col" v-if="meetingInfo.meetingFormat!='OTHER'">
						<div class="qrcode">
							<h4>观看直播<span>点击二维码下载</span></h4>
							<img :src="meetingInfo.codeUrl" alt="" @click="downloadQrCode(meetingInfo.codeUrl)">
						</div>
						<div class="linkUrl" v-if="meetingInfo.liveUrl">
							<h4>直播链接</h4>
							<a :href="meetingInfo.liveUrl" target="_blank">{{meetingInfo.liveUrl}}</a>
						</div>
					</div>

					<!-- <div class="col">
						<div class="qrcode">
							<h4>签到二维码（会前签到）</h4>
							<img :src="meetingInfo.signCodeUrl" alt="">
						</div>
					</div> -->
				</div>
			</div>
		</div>

		<el-dialog title="审核通过并提交" v-if="submitForReview" :visible.sync="submitForReview" width="400px"
			custom-class="submitForReview">
			<div class="form">
				<h2>提交事由：</h2>
				<div class="tips">提交事由将会在金蝶中的事由字段中做展示</div>
				<el-input type="textarea" maxlength="100" show-word-limit v-model="submitForReviewReason"
					placeholder="请输入提交事由"></el-input>
			</div>
			<div class="dialog-footer">
				<el-button size="small" @click="submitForReview = false">取 消</el-button>
				<el-button size="small" type="primary" @click="submitForReviewFun">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="文件预览" v-if="fileShow" :visible.sync="fileShow" width="95vw" custom-class="filePreview">
			<el-image :src="fileUrl" v-if="fileType == 'image'"></el-image>
			<div class="pdfFile" v-if="fileType == 'pdf'">
				<pdf :src="fileUrl" :page="currentPage" @num-pages="handleNumPages"></pdf>
				<p class="pages" v-if="numPages !== 0">总页数: {{ numPages }}</p>
				<div class="opts">
					<el-button @click="previousPages" :disabled="currentPage === 1">上一页</el-button>
					<el-button @click="nextPages" :disabled="currentPage === numPages">下一页</el-button>
				</div>
			</div>
			<div class="otherFile" v-if="fileType == 'file'">
				<iframe :src="`https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`"></iframe>
			</div>
		</el-dialog>

		<!-- 压缩包文件预览 -->
		<el-dialog v-if="zipVisible" :visible.sync="zipVisible" title="压缩包预览" custom-class="filePreview2">
			<div class="zip">
				<!-- 文件列表 -->
				<el-table v-if="zipData" :data="zipData" style="width: 100%" row-key="name" default-expand-all
					:tree-props="{children: 'children', hasChildren: 'hasChildren'}">
					<el-table-column label="文件名" min-width="580">
						<template slot-scope="scope">
							<div class="fileRow" @click="preview(scope.row.path)">
								<el-image v-if="scope.row.path&&isImage(scope.row.path)" :src="scope.row.path"
									fit="cover">
									<div slot="error" class="image-slot">
										<img style="width: 50px;height: 50px;"
											:src="require('@/assets/imgs/i_img.png')" />
									</div>
								</el-image>
								<el-image v-else-if="scope.row.path&&isPdf(scope.row.path)"
									:src="require('@/assets/imgs/pdf.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isZip(scope.row.path)"
									:src="require('@/assets/imgs/zip.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isPpt(scope.row.path)"
									:src="require('@/assets/imgs/ppt.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isDoc(scope.row.path)"
									:src="require('@/assets/imgs/doc.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isXls(scope.row.path)"
									:src="require('@/assets/imgs/xls.png')" fit="cover"></el-image>
								<el-image v-else-if="scope.row.path&&isZip(scope.row.path)"
									:src="require('@/assets/imgs/zip.png')" fit="cover"></el-image>
								<el-image v-else :src="require('@/assets/imgs/i_file.svg')" fit="cover"></el-image>
								<span>
									{{scope.row.name}}
								</span>
							</div>
							<!-- <hr v-if="scope.row.path">
							{{scope.row.path}} -->
						</template>
					</el-table-column>
					<!-- <el-table-column prop="type" label="类型" width="180"> </el-table-column> -->
				</el-table>
			</div>
		</el-dialog>

		<el-dialog title="劳务支付明细" v-if="dlalogOrder" :visible.sync="dlalogOrder" width="1300px"
			custom-class="filePreview">
			<!-- {{}} -->
			<div class="options">
				<el-button size="mini" type="primary" @click="ExportTableFun()">导出明细表</el-button>
			</div>
			<!-- {{dlalogOrderList}} -->
			<el-table :data="dlalogOrderList" style="width: 100%" fit>
				<el-table-column align="center" label="订单号" prop="orderNo" width="70"> </el-table-column>
				<el-table-column align="center" label="金蝶单号" prop="reimbursementNum" width="120"> </el-table-column>
				<el-table-column align="center" label="付款流水号" prop="paymentNo" min-width="200"> </el-table-column>
				<el-table-column align="center" label="所属项目" prop="projectName" min-width="180"> </el-table-column>
				<el-table-column align="center" label="用户姓名" prop="accountName"> </el-table-column>
				<el-table-column align="center" label="银行卡号" prop="bankcard" min-width="180"> </el-table-column>
				<el-table-column align="center" label="银行" prop="bank" min-width="130"> </el-table-column>
				<el-table-column align="center" label="身份证号" prop="idsn" min-width="130"> </el-table-column>
				<el-table-column align="center" label="支付类型" prop="customerSummary" width="100"> </el-table-column>
				<el-table-column align="center" label="账户变动" prop="amount"> </el-table-column>
				<el-table-column align="center" label="支付时间" prop="tradeDate" min-width="180"> </el-table-column>
				<el-table-column align="center" label="操作" fixed="right" min-width="100">
					<template slot-scope="scope">
						<a :href="scope.row.receiptUrl" target="_blank" style="color: #1890ff;">查看回执</a>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>

		<el-dialog :title="`修改【${detailOfServicesRow.fullName}】关于【会议主题：${detailOfServicesRow.meetingTitle}】的劳务信息`"
			v-if="dlalogdetailOfServices" :visible.sync="dlalogdetailOfServices" width="500px"
			custom-class="changeDetailOfServices">
			<!-- {{detailOfServicesRow}} -->
			<el-form class="DetailOfServicesForm" ref="form" :model="detailOfServicesRow" label-position="top">
				<el-form-item label="姓名">
					<el-input v-model="detailOfServicesRow.fullName"></el-input>
				</el-form-item>
				<el-form-item label="身份证号">
					<el-input v-model="detailOfServicesRow.cardId"></el-input>
				</el-form-item>
				<el-form-item label="手机号">
					<el-input v-model="detailOfServicesRow.phoneNumber"></el-input>
				</el-form-item>
				<el-form-item label="归属会议">
					<el-input v-model="detailOfServicesRow.meetingTitle"></el-input>
				</el-form-item>
				<el-form-item label="开户行">
					<el-input v-model="detailOfServicesRow.bankName"></el-input>
				</el-form-item>
				<el-form-item label="银行卡号">
					<el-input v-model="detailOfServicesRow.bankCardNumber"></el-input>
				</el-form-item>
				<el-form-item label="税前劳务金额">
					<el-input v-model.number="detailOfServicesRow.money" type="number"></el-input>
				</el-form-item>

				<el-form-item class="formFooter">
					<el-button @click="dlalogdetailOfServices = false">取消</el-button>
					<el-button type="primary" @click="changeDetail">确认修改</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import dict from '@/assets/dictionary/dictionary.js';
	import {
		officialMeetingDetail,
		officialMeetingFile,
		officialRoleList,
		officialAgreementList,
		officialOrderList,
		officialActuatorList, // 执行机构列表
		officialEnterPriseList, // 支持企业列表
		officialtMechanismList, // 立项单位列表
		meetingUserInfoList, // 参会人员列表
		getUserFileList, // 查询用户四证
		examineOfficialMeeting, // 进行审核
		meetingOrderList,
		exportYzfOrder,
		delOfficialMeetingVideo,
		officialMeetingOver,
		officialDelMeeting,
		ogetMeetingUrl,
		officialdownloadAgreement,
		officialddownloadAllFile,
		projectAssistantUserList,
		officialSendText,
		officialGetZipFile,
		officialGetImportOrderById, //官员根据ID获取导入订单
		officialEditImportOrderMessage, // 官员修改导入支付订单
		officialDeleteImportOrder, // 官员删除导入支付订单
		getofficialUserList
	} from '@/request/api.js';
	import color from "@/assets/color.js";
	import pdf from "vue-pdf";
	import baseUrl from "@/request/config.js"
	export default {
		name: 'meetingDetail',
		data() {
			return {
				loading: false,
				loadingText: '',
				videoList: [],
				baseUrl,
				token: '',
				dict, // 字典
				meetingInfo: '', // 会议详情
				roleList: '',
				agreementList: [],
				orderList: [],
				userList: [],
				projectOriginator: '', // 官员列表
				orgList: [], // 助教列表
				meetFile: '',
				tabCk: "3",
				actuator: '', // 执行机构
				enterPrise: '', // 支持企业
				mechanism: '', // 立项单位
				// activeNames: ['file1', 'file2', 'file3', 'file4', 'file5', 'file6'],
				viewVideoType: false, // 查看视频回放
				showVideo: '',
				certificateList: [], // 四证列表
				certificateShow: false,
				agreementPath: '', // 劳务协议
				agreementType: false,
				currentPage: 1, // 当前页码
				numPages: 0, // 设置总页码
				color,
				fileShow: false,
				zipVisible: false,
				fileUrl: '',
				fileType: '',
				dlalogOrderList: [],
				dlalogOrder: false,
				orgListUser: [],
				submitForReviewReason: '',
				submitForReview: false,

				zipData: '',

				detailOfServicesRow: '',
				dlalogdetailOfServices: false
			}
		},
		components: {
			pdf,
		},
		created() {
			if (this.$route.query.now) {
				this.tabCk = this.$route.query.now;
			}
			this.token = JSON.parse(localStorage.getItem('token')).token
			this.getDict();
			this.getOtherMeetingInfo();
			this.getofficialMeetingDetail();
			// this.getMeetingOrderList()

		},
		methods: {
			// 删除一条明细
			deleteDetail(row) {
				// 删除前二次确认
				this.$confirm('此操作将永久删除该条明细, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						// console.log(JSON.parse(JSON.stringify(row)))
						officialDeleteImportOrder({
							id: row.id
						}).then(res => {
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: '删除成功!'
								});
								this.getofficialOrderListFun();
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								});
							}
						})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
			},

			changeDetailShowDlog(row) {
				officialGetImportOrderById({
					id: row.id
				}).then(res => {
					console.log(res)
					if (res.code == 200) {
						this.detailOfServicesRow = res.data;
						this.dlalogdetailOfServices = true;
					}
				})
			},
			changeDetail() {
				officialEditImportOrderMessage(this.detailOfServicesRow).then(res => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.dlalogdetailOfServices = false;
						this.getofficialOrderListFun();
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					}
				})
			},


			File6Success(res, file) {
				// console.log(res)
				// console.log(file.name)
				this.loading = false;
				if (res.code == 200) {
					this.getofficialOrderListFun();
					this.$message({
						message: `文件 ${file.name} 上传成功。${res.data.msg}`,
						type: 'success'
					});
				} else {
					this.$message({
						message: `${res.msg}`,
						type: 'error'
					});
				}
			},


			// 文件上传中
			onProgress(event, file, fileList) {
				this.loading = true;
				if (event.percent < 100) {
					this.loadingText = `文件上传中···，当前${event.percent.toFixed(2)}%`;
				} else {
					this.loadingText = '文件上传成功，服务器正在处理···';
				}
			},

			// 发送提醒签协议短信
			sendTextFun(item) {
				let data = {
					ids: [item.id]
				};
				console.log(data)
				officialSendText(data).then(res => {
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.$notify({
							type: 'success',
							title: '发送成功',
							message: res.msg,
							position: 'bottom-right'
						});
						this.getOtherMeetingInfo()
					};
				})

			},
			downloadQrCode(link) {
				// 下载图片
				let a = document.createElement('a')
				a.href = link
				a.download = '二维码'
				a.click(); // 自动触发点击事件下载文件
			},

			// 获取当前会议所属执行机构下的人员列表
			getOrgListUser() {
				getofficialUserList({
					// projectAssistantUserList({
					projectId: this.meetingInfo.projectId,
					actuatorId: this.meetingInfo.meetingActuatorId
				}).then(res => {
					if (res.code == 200) {
						// console.log("执行机构人员列表")
						this.orgListUser = res.data
					}
				})
			},

			// 下载所有文档
			downloadAllFun(command) {
				this.loading = true;
				this.loadingText = '正在下载中，请稍后...'

				let data = {
					meetingId: this.$route.query.meetingId
				}
				if (command == 1) {
					data.fileType = 'file5';
				} else if (command == 2) {
					data.fileType = 'file7';
				} else if (command == 3) {
					data.fileType = 'file12';
				} else if (command == 4) {
					data.fileType = 'file11';
				} else if (command == 6) {
					data.fileType = 'file4';
				} else if (command == 7) {
					data.fileType = 'file6';
				} else if (command == 8) {
					data.fileType = 'file3';
				};
				// <el-dropdown-item command="1">导出所有劳务协议</el-dropdown-item>
				// <el-dropdown-item command="2">导出全部用户文件</el-dropdown-item>
				// <el-dropdown-item command="3">导出所有通用文件</el-dropdown-item>
				// <el-dropdown-item command="4">导出所有发票</el-dropdown-item>
				// <el-dropdown-item command="6">导出所有会议照片</el-dropdown-item>
				// <el-dropdown-item command="7">导出劳务协议明细表</el-dropdown-item>
				// <el-dropdown-item command="8">导出签到表</el-dropdown-item>
				// <el-dropdown-item command="5">下载所有文件</el-dropdown-item>

				officialddownloadAllFile(data).then(res => {
					let blob = new Blob([res], {
						type: 'application/zip'
					});
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.target = '_blank';
					a.style.display = 'none';
					a.href = url;
					a.download = '会议文件.zip';
					a.click();
					window.URL.revokeObjectURL(url);
					this.loading = false;
				})
			},

			handleCommand(command) {
				console.log(command)
				this.downloadAllFun(command);
			},

			// 下载所有的劳务协议
			downloadAgreementFun() {
				this.loading = true;
				this.loadingText = '正在下载中，请稍后...'
				officialdownloadAgreement({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					let blob = new Blob([res], {
						type: 'application/zip'
					});
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement('a');
					a.target = '_blank';
					a.style.display = 'none';
					a.href = url;
					a.download = '劳务协议.zip';
					a.click();
					window.URL.revokeObjectURL(url);
					this.loading = false;
				})
			},
			// 文件夹判空
			noFile(item) {
				let arr = [];
				for (let i in item) {
					arr = [...arr, ...item[i]];
				}
				if (arr.length) {
					return false;
				} else {
					return true;
				}
			},
			// 修改会议信息
			ChangeMeetingDetail() {
				this.$router.push({
					name: 'officialCreatemeet',
					query: {
						mId: this.$route.query.meetingId
					}
				})
			},
			// 进入会议
			enterTheMeeting() {
				let data = {
					meetingId: this.$route.query.meetingId
				}
				ogetMeetingUrl(data).then(res => {
					if (res.code == 200) {
						window.open(res.data)
					} else {
						// this.getMeetInfo();
						this.$message({
							message: res.msg,
							type: 'error'
						});
						this.$notify({
							type: 'error',
							title: '错误',
							message: res.msg,
							position: 'bottom-right'
						});
					}
				})
			},
			// 结束会议
			closeTheMeeting() {
				let data = {
					meetingId: this.$route.query.meetingId
				};
				this.$confirm('此操作将会结束当前的会议, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// console.log(data)
					this.loading = true;
					this.loadingText = '加载中，请稍后...'
					officialMeetingOver(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							// this.$router.push({
							// 	name: 'officialMeetinglist',
							// 	query: {
							// 		pId: this.meetingInfo.projectId
							// 	}
							// });
							// this.getMeetInfo();
							this.getOtherMeetingInfo();
							this.getofficialMeetingDetail();
							// this.tabCk = '3';
							this.$message({
								type: 'info',
								message: '会议已结束!'
							});
							this.$notify({
								type: 'info',
								title: '提示',
								message: '会议已结束!',
								position: 'bottom-right'
							});
						};
					});
				});
			},
			// 取消会议
			delMeetingFun() {
				this.$confirm(`此操作删除会议【${this.meetingInfo.meetingTitle}】, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.loadingText = '加载中，请稍后...'
					let data = {
						id: this.$route.query.meetingId
					};
					officialDelMeeting(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '删除成功!'
							});
							// let projectId = JSON.parse(localStorage.getItem('projectDetails')).id;
							this.$router.push({
								name: 'officialMeetinglist',
								query: {
									pId: this.meetingInfo.projectId
								}
							});
						};
					});
				});
			},
			// 会议视频上前执行操作
			videoBeforeUpload(file) {
				this.loading = true;
				this.loadingText = '正在上传中 请不要进行其他操作 稍等片刻···'
			},
			// 会议视频上前成功
			videoSuccess(res, file) {
				this.loading = false;
				this.$message({
					message: `文件 ${file.name} 上传成功。`,
					type: 'success'
				});
				this.getofficialMeetingDetail();
			},
			// 导出劳务明细表
			ExportTableFun() {
				exportYzfOrder({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					console.log(res)
					// 下载流文件
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel;charset=utf-8"
					});
					let url = window.URL.createObjectURL(blob);
					let link = document.createElement("a");
					link.style.display = "none";
					link.href = url;
					link.setAttribute("download", "劳务明细表.xlsx");
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
			},
			// 获取订单明细
			getMeetingOrderList() {
				let data = {
					meetingId: this.$route.query.meetingId
				};
				meetingOrderList(data).then(res => {
					console.log(JSON.parse(JSON.stringify(res)));

					if (res.code == 200) {
						if (res.data.records.length) {
							this.dlalogOrderList = res.data.records;
							this.dlalogOrder = true;
						} else {
							this.$message({
								message: '暂无明细',
								type: 'info'
							});
						}
					} else {
						this.$message({
							message: res.message,
							type: 'error'
						});
					}
				})
			},
			// 去上传文件
			goUpload(row) {
				console.log(row)
				this.$router.push({
					path: '/officialUploadFile',
					query: {
						id: row.id
					}
				})
			},
			getZipFileFun(id, name) {
				// console.log(id, name)
				let zipId = name.split('-')[0];
				officialGetZipFile({
					zipId: zipId
				}).then(res => {
					if (res.code == 200) {
						this.zipVisible = true;
						// console.log(res.data)
						this.zipData = res.data.children
					}
				})
			},
			// 预览文件
			preview(url, name) {

				if (url) {
					let type = url.split('.').pop().toLowerCase();
					if (this.isImage(url)) {
						this.fileType = 'image';
						this.fileShow = true;
					} else if (this.isZip(url)) {
						this.fileType = 'zip';
						this.getZipFileFun(url, name)
					} else if (this.isPdf(url)) {
						this.fileType = 'pdf';
						this.fileShow = true;
					} else if (this.isFile(url)) {
						this.fileType = 'file';
						this.fileShow = true;
					} else {
						return false;
					}
					this.fileUrl = url;
				}
			},
			// 下载单独文件
			downloadFile(urlLink, fileName) {
				const x = new XMLHttpRequest()
				x.open('GET', urlLink, true)
				x.responseType = 'blob'
				x.onload = function() {
					const url = window.URL.createObjectURL(x.response)
					const a = document.createElement('a')
					a.href = url
					a.download = fileName
					a.click()
				}
				x.send()
			},
			// 材料审核
			materialAudit(flag) {
				// 审核操作 判断是通过 还是驳回
				if (flag) {
					// 如果通过
					this.submitForReviewReason = '万木智会-个人劳务结算-' + JSON.parse(JSON.stringify(this.meetingInfo
						.meetingTitle));
					this.submitForReview = true; // 弹出通过原因弹窗
				} else {
					// 如果驳回
					this.reviewAndReject(flag); // 调用驳回方法
				}
			},
			// 审核驳回 并给出驳回原因
			reviewAndReject() {
				let data = {
					id: this.$route.query.meetingId,
					status: "ERROR",
					errorMessage: ''
				};
				this.$prompt('请输入驳回理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^\S+$/,
					inputErrorMessage: '驳回理由不能为空'
				}).then(({
					value
				}) => {
					this.loading = true;
					data.errorMessage = value;
					examineOfficialMeeting(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							if (value) {
								this.$message({
									message: '已驳回',
									type: 'success'
								});
								this.getofficialMeetingDetail();
							} else {
								this.$message({
									message: res.msg,
									type: 'success'
								});
							}
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
						}
					})
				})
			},
			// 提交审核材料到金蝶
			submitForReviewFun() {
				let data = {
					id: this.$route.query.meetingId,
					status: "SUCCESS",
					reason: this.submitForReviewReason
				};

				this.loading = true;
				this.loadingText = '正在提交 请稍等···'
				this.submitForReview = false;
				examineOfficialMeeting(data).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
						this.getofficialMeetingDetail();
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						});
					};
				});
			},
			// 获取会议详情
			getofficialMeetingDetail() {
				// console.log(this.$route.query.meetingId);
				officialMeetingDetail({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					if (res.code == 200) {
						this.meetingInfo = res.data;
						this.getOrgListUser();
					}
				})
			},
			// 查看四证信息
			viewCertificate(row) {
				// console.log(row.id);
				getUserFileList({
					userId: row.id
				}).then(res => {
					this.certificateList = res.data.map(item => item.filePath)
					this.certificateShow = true;
				})
			},
			// 查看劳务协议
			agreementShow(row) {
				console.log(row.agreementPath)
				this.agreementPath = row.agreementPath;
				this.agreementType = true;
			},
			// 获取各种字典
			getDict() {
				// 执行机构
				officialActuatorList().then(res => {
					if (res.code == 200) {
						let data = {};
						res.data.forEach(item => {
							data[item.id] = item.deptName;
						});
						this.actuator = data;
					}
				})
				// 支持企业
				officialEnterPriseList().then(res => {
					if (res.code == 200) {
						let data = {};
						res.data.forEach(item => {
							data[item.id] = item.deptName;
						});
						this.enterPrise = data;
					}
				})
				// 立项单位
				officialtMechanismList().then(res => {
					if (res.code == 200) {
						let data = {};
						res.data.forEach(item => {
							data[item.id] = item.deptName;
						});
						this.mechanism = data;
					}
				})

			},
			// 数组去除空值
			ClearNullArr(arr) {
				for (var i = 0, len = arr.length; i < len; i++) {
					if (!arr[i] || arr[i] == '' || arr[i] == 'null' || arr[i] === undefined) {
						arr.splice(i, 1);
						len--;
						i--;
					}
				}
				return arr;
			},
			getofficialOrderListFun() {
				// 劳务列表
				officialOrderList({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					if (res.code == 200) {
						this.orderList = res.data;
						this.$forceUpdate();
					}
				})
			},
			// 获取其他相关会议信息
			getOtherMeetingInfo() {
				this.getofficialOrderListFun();

				// 获取会议文件
				officialMeetingFile({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					if (res.code == 200) {
						let data = res.data;
						for (let key in data.errorMessages) {
							let msg = data.errorMessages[key];
							if (key == 'file7') {
								for (let key2 in msg) {
									let msg2 = msg[key2];
									for (let key3 in msg2) {
										let msg3 = msg2[key3];
										if (msg3) {
											data.errorMessages[key][key2][key3] = this.ClearNullArr(msg3
												.split(
													'\n'));
										}
									}
								}
							} else {
								if (msg) {
									data.errorMessages[key] = this.ClearNullArr(msg.split('\n'));
								};
							};
						};
						this.annotation = data.annotation;
						this.meetFile = data;
						// console.log(JSON.parse(JSON.stringify(this.meetFile)));
					};
				});

				// 获取角色列表
				officialRoleList({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					if (res.code == 200) {
						this.roleList = res.data;
					};
				});

				// 获取会议议程
				officialAgreementList({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					if (res.code == 200) {
						this.agreementList = res.data;
					}
				})

				//获取会议人员列表
				meetingUserInfoList({
					meetingId: this.$route.query.meetingId
				}).then(res => {
					if (res.code == 200) {
						this.userList = res.data;
					}
				})
			},
			// 查看视频回放
			viewVideo(row) {
				this.viewVideoType = true;
				this.showVideo = row.videoPath;
			},
			// 下载
			download(row) {
				window.open(row.videoPath)
			},
			// 删除视频
			deleteVideo(row) {
				console.log(row);
				// 确认弹窗
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delOfficialMeetingVideo({
						id: row.id
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.getofficialMeetingDetail();
						} else {
							this.$message.error(res.msg);
						}
					})
				})
			},
			// PDF预览获取页码总数
			handleNumPages(numPages) {
				this.numPages = numPages;
			},
			// PDF预览点击之后页面切换为上一页
			previousPages() {
				this.currentPage--;
			},
			// PDF预览点击之后页面切换为上一页
			nextPages() {
				this.currentPage++
			},
			// 截取字符串 英文截取前两位 中文截取第一位
			subString(str) {
				if (str) {
					if (str.length > 1) {
						if (/.*[\u4e00-\u9fa5]+.*$/.test(str)) {
							return str.substr(0, 1);
						} else {
							return str.substr(0, 2);
						}
					} else {
						return str;
					}
				} else {
					return '';
				}
			},
			isImage(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
				return imageExtensions.includes(extension);
			},
			isFile(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
				return imageExtensions.includes(extension);
			},
			isDoc(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['doc', 'docx'];
				return imageExtensions.includes(extension);
			},
			isXls(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['xls', 'xlsx'];
				return imageExtensions.includes(extension);
			},
			isPpt(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['ppt', 'pptx'];
				return imageExtensions.includes(extension);
			},
			isPdf(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['pdf'];
				return imageExtensions.includes(extension);
			},
			isZip(filename) {
				const extension = filename.split('.').pop().toLowerCase();
				const imageExtensions = ['zip', 'rar', '7z', 'tar'];
				return imageExtensions.includes(extension);
			},
		},
	}
</script>

<style lang="scss" scoped>
	#meetingDetail {
		padding: 30px;
		overflow-y: scroll;

		.detailHeader {
			display: flex;
			flex-direction: column;
			padding: 0 5px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, .3);
			border-radius: 10px;
			margin-bottom: 20px;

			.title {
				color: #171725;
				font-size: 24px;
				font-weight: bold;
				margin: 20px 20px 0 20px;

			}

			.tabs {
				display: flex;
				flex-direction: row;

				.item {
					font-size: 16px;
					width: 19%;
					height: 80px;
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					border-right: 1px solid #f6f8f9;
					position: relative;

					&:last-child {
						border: none;
					}

					&::after {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 0px;
						background-color: #0065FF;
						transition: all .3s;
					}

				}

				.item.active {
					color: #1B69FD;

					&::after {
						height: 10px;
					}
				}

				.tab3 {
					color: #0065ff;
					font-weight: bold;
				}
			}
		}

		.details {
			display: flex;
			flex-direction: column;

			.detailsMain {
				display: flex;
				flex-direction: row;
			}

			.options {
				margin: 20px;
				text-align: center;
			}

			.detailLeft,
			.detailRight {
				flex: 1;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, .3);
				border-radius: 20px;
				padding: 30px;

				.el-row {
					margin-bottom: 20px;

					.el-col {
						display: flex;
						flex-direction: column;

						.tit {
							margin: 0;
							font-size: 16px;
							color: #1A1F2C;
							margin-bottom: 10px;
						}

						.main {
							color: #171725;
							background-color: #F6F7F9;
							border-radius: 10px;
							height: 54px;
							padding: 0 20px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.users {
								display: flex;
							}

							.user {
								display: flex;
								align-items: center;
								margin-left: 10px;

								.avatar {
									width: 34px;
									height: 34px;
									box-shadow: 0 0 4px rgba(0, 0, 0, .1);
									border-radius: 50%;
									display: flex;
									align-items: center;
									justify-content: center;
									margin-right: 5px;
								}
							}
						}

						.schedule {
							display: flex;
							flex-direction: column;

							.node {
								display: flex;
								align-items: center;
								height: 40px;

								.time,
								.type {
									flex: 1;
									display: flex;
									align-items: center;

									.el-avatar {
										width: 30px;
										height: 30px;
										line-height: 30px;
										border-radius: 50%;
										margin-right: 5px;
									}

									.el-checkbox {
										margin-right: 20px;
									}

									.el-icon-date {
										margin-right: 5px;
									}
								}
							}
						}

						.mainArea {
							background-color: #F6F7F9;
							border-radius: 10px;
							padding: 20px;
							min-height: 100px;
						}
					}
				}
			}

			.detailLeft {
				margin-right: 15px;


			}

			.detailRight {
				margin-left: 15px;
			}
		}


		::v-deep .detailServices {

			>.opts {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.upload {
					margin: 0 10px;
				}
			}


			.agreementDialog {
				.el-dialog {
					margin: 0 !important;
					height: 95vh;
					overflow: hidden;
					overflow-y: auto;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}

				.pages {
					text-align: center;
				}

				.opts {
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: space-around;
				}
			}

			.opts2 {
				margin-top: 20px;
				text-align: center;
			}
		}

		.participationLink {
			.row {
				display: flex;

				.col {
					flex: 1;
				}
			}

			.qrcode,
			.linkUrl {
				margin-bottom: 30px;

				h4 {
					font-size: 22px;
					margin-bottom: 10px;
					width: 300px;
					text-align: center;
					display: flex;
					justify-content: center;
					flex-direction: column;

					span {
						font-size: 12px;
						color: #999;
					}
				}

				img {
					display: block;
					width: 300px;
					height: 300px;
					border-radius: 20rpx;
					box-shadow: 0 0 20px rgba(0, 0, 0, .1);
					overflow: hidden;
					cursor: pointer;
				}

				span {
					font-size: 14px;
				}
			}

			.options {
				display: flex;
				justify-content: center;

				.el-button {
					width: 200px;
				}
			}
		}


		::v-deep .livePlayback {
			.agreementDialog {
				video {
					width: 100%;
					height: 500px;
				}
			}
		}

		::v-deep .comMate {

			.option {
				margin-bottom: 10px;
			}

			.complianceMaterials {
				display: flex;
				flex-direction: column;

				.assemble {
					display: flex;
					flex-direction: row;
					margin-bottom: 20px;

					.compRow {
						flex: 1;

						margin: 0 10px;

						&:last-child {
							margin-right: 0;
						}

						&:first-child {
							margin-left: 0;
						}
					}
				}

				.noFile {
					width: 100px;
					height: 80px;
					margin: 0 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					box-shadow: 0 2px 18px rgba(190, 190, 190, 0.31);
					border-radius: 10px;
					padding: 10px;
					color: #ccc;
					font-size: 16px;

					i {
						font-size: 30px;
					}
				}

				.compRow {
					display: flex;
					flex-direction: column;
					box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
					border-radius: 10px;
					margin-bottom: 20px;
					padding: 20px;

					h1 {
						font-size: 18px;
						font-weight: bold;
						color: #333;
					}

					h2 {
						font-size: 14px;
						color: #666;
						font-weight: bold;
					}

					.compTit {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						margin-bottom: 10px;
						word-break: break-word;

						// h1,
						// h2 {
						// 	width: 100%;
						// }

						.el-icon-warning {
							font-size: 14px;
							margin-left: 10px;
							color: #F56C6C;
						}

						.el-icon-success {
							font-size: 14px;
							margin-left: 10px;
							color: #67C23A;
						}

						span {
							display: inline;
							font-size: 12px;
							margin-left: 4px;
							color: #F56C6C;
						}

						label {
							display: inline;
							font-size: 12px;
							margin-left: 4px;
							color: #999;
							font-weight: normal;
						}
					}

					.compVal {
						display: flex;
						align-items: flex-start;
						flex-wrap: wrap;
						width: 100%;

						.item {
							display: flex;
							flex-direction: column;
							align-items: center;
							padding: 5px;
							margin: 5px;
							width: 130px;
							cursor: pointer;
							position: relative;

							* {
								cursor: pointer;
								transition: all .3s;
							}

							&:hover {
								.FileName {
									color: #409EFF;
									word-break: break-all;
								}

								.FileView .el-image {
									box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
								}

								.viewOpts {
									opacity: 1;
								}
							}
						}

						.FileView {
							width: 100px;
							height: 100px;
							display: flex;
							position: relative;

							>.new {
								position: absolute;
								left: 0;
								top: 0;
								z-index: 100;
								line-height: 1;
								padding: 3px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #f56c6c;
								color: #fff;
								border-radius: 5px;
								font-size: 12px;
								font-weight: bold;
							}

							.el-image {
								width: 100%;
								height: 100%;
								border-radius: 20px;
								box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
							}
						}

						.FileName {
							padding-top: 5px;
							font-size: 13px;
							color: #555;
							font-weight: bold;
							word-break: break-all;
							overflow: hidden;
							/*内容超出后隐藏*/
							text-overflow: ellipsis;
							/*超出内容显示为省略号*/
							display: -webkit-box;
							/*将对象作为弹性伸缩盒子模型显示*/
							-webkit-box-orient: vertical;
							/*从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）*/
							-webkit-line-clamp: 2;
							/*这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。可根据需要设置超出多少行后显示省略号*/
						}
					}

					.compFile7 {
						// padding-left: 16px;

						.item {
							margin-bottom: 10px;

							&:last-child {
								margin: 0;
							}


						}

						.fileRow {
							display: flex;
							align-items: flex-start;
							flex-wrap: wrap;
							width: 100%;
						}

						.fileItem {
							display: flex;
							flex-direction: column;
							align-items: center;
							padding: 5px;
							margin: 5px;
							width: 130px;
							cursor: pointer;
							position: relative;

							* {
								cursor: pointer;
								transition: all .3s;
							}

							&:hover {
								.FileName {
									color: #409EFF;
									word-break: break-all;
								}

								.FileView .el-image {
									box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
								}

								.viewOpts {
									opacity: 1;
								}
							}
						}

						.FileView {
							width: 100px;
							height: 100px;
							display: flex;
							position: relative;

							>.new {
								position: absolute;
								left: 0;
								top: 0;
								z-index: 100;
								line-height: 1;
								padding: 3px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #f56c6c;
								color: #fff;
								border-radius: 5px;
								font-size: 12px;
								font-weight: bold;
							}

							.el-image {
								width: 100%;
								height: 100%;
								border-radius: 20px;
								box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
							}
						}

						.FileName {
							padding-top: 5px;
							font-size: 13px;
							color: #555;
							font-weight: bold;
							overflow: hidden;
							word-break: break-all;
							/*内容超出后隐藏*/
							text-overflow: ellipsis;
							/*超出内容显示为省略号*/
							display: -webkit-box;
							/*将对象作为弹性伸缩盒子模型显示*/
							-webkit-box-orient: vertical;
							/*从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）*/
							-webkit-line-clamp: 2;
							/*这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。可根据需要设置超出多少行后显示省略号*/
						}


					}

					.viewOpts {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: rgba(90, 90, 90, 0.5);
						border-radius: 10px;
						display: flex;
						flex-direction: column;
						align-items: center;
						padding: 25px 10px;
						opacity: 0;
						z-index: 101;

						.btn {
							width: 100%;
							flex: 1;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 16px;
							color: #fff;
							font-weight: bold;
							text-shadow: 0 0 5px #000;
							cursor: pointer;
							transition: all .3s;

							&:hover {
								text-shadow: 0 0 20px #000;
								font-size: 18px;
							}
						}
					}

				}
			}

			.null {
				background-color: #fff;
				text-align: center;
				font-size: 16px;
				color: #999;
				box-shadow: 0px 2px 13px rgba(203, 203, 203, .3);
				padding: 30px;
				border-radius: 10px;
				margin-bottom: 20px;
			}

			.opts {
				display: flex;
				align-items: center;
				justify-content: center;

				.el-button {
					width: 140px;
				}
			}
		}

		::v-deep .filePreview {
			margin: 0 !important;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-height: 95vh;
			overflow-y: auto;

			.el-dialog__body {
				display: flex;
				justify-content: center;

				.pdfFile,
				.otherFile {
					width: 100%;

					iframe {
						width: 100%;
						min-height: 80vh;
						border: none;
					}
				}

				.pages {
					text-align: center;
				}

				.opts {
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: space-around;
				}

				.options {
					margin-bottom: 10px;

					.el-button {
						height: 50px;
						font-size: 16px;
						font-weight: bold;
					}
				}

				.pages {
					text-align: center;
				}

				.opts {
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: space-around;
				}


			}
		}

		::v-deep .changeDetailOfServices {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0 !important;
			max-height: 95vh;
			overflow: hidden;
			overflow-y: auto;

			.el-dialog__body {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: 10px;
			}

			.DetailOfServicesForm {
				.el-form-item {
					margin-bottom: 10px;
				}

				.el-form-item__label {
					padding-bottom: 5px;
					line-height: 20px;
				}

				.formFooter {
					text-align: center;
				}
			}
		}

		::v-deep .filePreview2 {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0 !important;
			max-height: 95vh;
			overflow: hidden;
			overflow-y: auto;

			.el-dialog__body {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding-top: 0;
			}

			.fileRow {
				display: inline-flex;
				align-items: center;

				.el-image {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
		}

		::v-deep .submitForReview {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0 !important;
			max-height: 95vh;
			overflow: hidden;
			overflow-y: auto;

			.el-dialog__body {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0 20px;

				.form {
					display: flex;
					flex-direction: column;

					h2 {
						margin-bottom: 5px;
						font-size: 14px;
						color: #666;
					}

					.tips {
						margin-bottom: 10px;
						font-size: 12px;
						color: #999;
					}
				}
			}

			.dialog-footer {
				text-align: center;
				padding: 10px 20px 20px;

				.el-button {
					width: 100px;
				}
			}
		}

	}
</style>